import React from 'react';

const InputGrid = ({children}) => {
  return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
        {children}
      </div>
  );
};

export default InputGrid;
