import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {setDatabase} from "@/lib/axios";

import InstallerContext from "@/context/InstallerContext";

import Step from "@/components/Step";
import StepTitle from "@/components/StepTitle";
import PrevButton from "@/components/PrevButton";
import NextButton from "@/components/NextButton";
import BackgroundCard from "@/components/BackgroundCard";
import ButtonWrapper from "@/components/ButtonWrapper";
import ErrorMessage from "@/components/ErrorMessage";
import Label from "@/components/Label";
import FormGroup from "@/components/FormGroup";
import InputGroup from "@/components/InputGroup";
import Input from "@/components/Input";
import InputGrid from "@/components/InputGrid";


const DatabaseSettings = ({nextStep, previousStep}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const {data, updateDatabaseData} = useContext(InstallerContext)
  const {database} = data;
  
  const handleNextStep = (e) => {
    e.preventDefault();
    setLoading(true);
    setDatabase(database)
      .then(({data}) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setErrors([]);
          nextStep();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const disabled = loading || !database.host || !database.username || !database.password || !database.database;
  
  return (
      <Step>
        <StepTitle>
          {t('steps.2.title')}
        </StepTitle>
        
        <ErrorMessage errors={errors}/>
        
        <BackgroundCard>
          <InputGrid>
            <FormGroup>
              <Label htmlFor="dbHost">
                {t('steps.2.inputs.host')}
              </Label>
              <InputGroup error={database.host === ''}>
                <Input
                    type="text"
                    id="dbHost"
                    placeholder="example: localhost"
                    value={database.host || "localhost"}
                    onChange={e => updateDatabaseData('host', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="dbPort">
                {t('steps.2.inputs.port')}
              </Label>
              <InputGroup error={database.port === ''}>
                <Input
                    type="text"
                    id="dbPort"
                    placeholder="example: 3306"
                    value={database.port || "3306"}
                    onChange={e => updateDatabaseData('port', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </InputGrid>
          
          <FormGroup>
            <Label htmlFor="dbName">
              {t('steps.2.inputs.database')}
            </Label>
            <InputGroup error={database.database === ''}>
              <Input
                  type="text"
                  id="dbName"
                  placeholder="example: leaderos_db"
                  value={database.database || ""}
                  onChange={e => updateDatabaseData('database', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="dbUsername">
              {t('steps.2.inputs.username')}
            </Label>
            <InputGroup error={database.username === ''}>
              <Input
                  type="text"
                  id="dbUsername"
                  placeholder="example: leaderos_user"
                  value={database.username || ""}
                  onChange={e => updateDatabaseData('username', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="dbPassword">
              {t('steps.2.inputs.password')}
            </Label>
            <InputGroup error={database.password === ''}>
              <Input
                  type="password"
                  id="dbPassword"
                  placeholder="**********"
                  value={database.password || ""}
                  autoComplete="off"
                  onChange={e => updateDatabaseData('password', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </BackgroundCard>
        
        <ButtonWrapper>
          <PrevButton previousStep={previousStep}/>
          <NextButton nextStep={handleNextStep} disabled={disabled} loading={loading}/>
        </ButtonWrapper>
      </Step>
  );
};

export default DatabaseSettings;