import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {createAdmin} from "@/lib/axios";

import InstallerContext from "@/context/InstallerContext";

import Step from "@/components/Step";
import StepTitle from "@/components/StepTitle";
import PrevButton from "@/components/PrevButton";
import NextButton from "@/components/NextButton";
import BackgroundCard from "@/components/BackgroundCard";
import ButtonWrapper from "@/components/ButtonWrapper";
import ErrorMessage from "@/components/ErrorMessage";
import Label from "@/components/Label";
import InputGroup from "@/components/InputGroup";
import FormGroup from "@/components/FormGroup";
import Input from "@/components/Input";
import InputGrid from "@/components/InputGrid";

const CreateAdmin = ({nextStep, previousStep}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const {data, updateAdminData} = useContext(InstallerContext)
  const {database, admin} = data;
  
  const handleNextStep = (e) => {
    e.preventDefault();
    setLoading(true);
    createAdmin(database, admin)
      .then(({data}) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setErrors([]);
          nextStep();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const disabled = loading || !admin.username || !admin.email || !admin.password || !admin.confirmPassword || admin.confirmPassword !== admin.password;
  
  return (
      <Step>
        <StepTitle>
          {t('steps.4.title')}
        </StepTitle>
        
        <ErrorMessage errors={errors} />
        
        <BackgroundCard>
          <FormGroup>
            <Label htmlFor="email">
              {t('steps.4.inputs.email')}
            </Label>
            <InputGroup error={admin.email === ''}>
              <Input
                  type="email"
                  id="email"
                  placeholder="example: admin@gmail.com"
                  value={admin.email || ""}
                  autoComplete="email"
                  onChange={e => updateAdminData('email', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="username">
              {t('steps.4.inputs.username')}
            </Label>
            <InputGroup error={admin.username === ''}>
              <Input
                  type="text"
                  id="username"
                  placeholder="example: LeaderOS"
                  value={admin.username || ""}
                  onChange={e => updateAdminData('username', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <InputGrid>
            <FormGroup>
              <Label htmlFor="password">
                {t('steps.4.inputs.password')}
              </Label>
              <InputGroup error={admin.password === ''}>
                <Input
                    type="password"
                    id="password"
                    placeholder="**********"
                    value={admin.password || ""}
                    autoComplete="new-password"
                    onChange={e => updateAdminData('password', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirmPassword">
                {t('steps.4.inputs.confirm_password')}
              </Label>
              <InputGroup error={admin.confirmPassword === '' || (admin.confirmPassword !== null && admin.confirmPassword !== admin.password)}>
                <Input
                    type="password"
                    id="confirmPassword"
                    placeholder="**********"
                    value={admin.confirmPassword || ""}
                    autoComplete="new-password"
                    onChange={e => updateAdminData('confirmPassword', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </InputGrid>
        </BackgroundCard>
        
        <ButtonWrapper>
          <PrevButton previousStep={previousStep} />
          <NextButton nextStep={handleNextStep} disabled={disabled} loading={loading} />
        </ButtonWrapper>
      </Step>
  );
};

export default CreateAdmin;