import React, {Suspense} from 'react';

import {InstallerProvider} from "@/context/InstallerContext";

import SetupWizard from '@/components/SetupWizard';
import Loading from "@/components/Loading";

import '@/styles/app.css';

function App() {
  return (
    <Suspense fallback={<Loading />}>
        <main className="bg-white flex items-center justify-center">
          <div className="relative bg-white flex relative w-full md:min-h-screen">
            <InstallerProvider>
              <SetupWizard />
            </InstallerProvider>
          </div>
        </main>
      </Suspense>
  );
}

export default App;
