import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";

const InputError = ({show}) => {
  if (!show) return false;
  
  return (
      <div className="right-3 top-3 absolute rounded-lg w-6 h-6 bg-red-400 text-white flex items-center justify-center">
        <FontAwesomeIcon icon={faExclamation} />
      </div>
  );
};

export default InputError;