import React, {useContext, useEffect, useState} from 'react';
import {When} from "react-if";
import ClickAwayListener from 'react-click-away-listener';
import {getCurrencies} from "@/lib/axios";
import InstallerContext from "@/context/InstallerContext";
import {useTranslation} from "react-i18next";

const CurrencySelector = () => {
  const {t} = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const {data, updateGeneralData} = useContext(InstallerContext);
  const {general} = data;
  
  const handleChange = (currency) => {
    setDropdownOpen(false);
    updateGeneralData("currency", currency);
  }
  
  useEffect(() => {
    getCurrencies().then(({data}) => {
      setCurrencies(data);
    });
  }, []);
  
  return (
      <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
        <div className="relative w-full">
          <button onClick={() => setDropdownOpen(!dropdownOpen)} className="input text-left w-full">
            {general.currency || t('select_currency')}
          </button>
          
          <When condition={dropdownOpen}>
            <div className="absolute w-full rounded-xl bg-gray-100 py-2 h-52 overflow-auto bottom-14 border border-gray-200">
              {Object.keys(currencies).map((key) =>
                  <div key={key} onClick={() => handleChange(key)} className="py-2 px-4 text-gray-500 text-sm transition hover:bg-gray-200/75 cursor-pointer">
                    {key + " - " + currencies[key].symbol}
                  </div>
              )}
            </div>
          </When>
        </div>
      </ClickAwayListener>
  );
};

export default CurrencySelector;