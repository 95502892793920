import React from 'react';

const FormGroup = ({children}) => {
  return (
      <div className="mb-6">
        {children}
      </div>
  );
};

export default FormGroup;
