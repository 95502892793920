import React from 'react';
import {useTranslation} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

import Step from "@/components/Step";
import StepTitle from "@/components/StepTitle";

const InstallingError = ({messages}) => {
  const {t} = useTranslation();
  
  return (
      <Step>
        <StepTitle>
          {t('steps.5.installing_error.title')}
        </StepTitle>
        
        <div className="bg-red-50 rounded-xl mt-6 py-6 px-10 text-red-500">
          <div className="mx-auto w-20 h-20 rounded-2xl flex items-center justify-center">
            <FontAwesomeIcon className="h-16 w-16" icon={faExclamationTriangle} />
          </div>
          <div className="max-w-lg mt-4 text-center mx-auto text-lg flex flex-col gap-y-4">
            <div>
              {t('steps.5.installing_error.description')}
            </div>
            
            <div>
              {messages.map((message, index) => (
                  <div key={index}>{message}</div>
              ))}
            </div>
          </div>
        </div>
      </Step>
  );
};

export default InstallingError;