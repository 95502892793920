import React from 'react';
import InputError from "@/components/InputError";

const InputGroup = ({children, error}) => {
  return (
      <div className="relative">
        {children}
        <InputError show={error}/>
      </div>
  );
};

export default InputGroup;
