import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {If, Then, Else} from "react-if";
import {getCompatibility} from "@/lib/axios";

import Step from "@/components/Step";
import NextButton from "@/components/NextButton";
import CompatibilityCard from "@/components/CompatibilityCard";
import StepTitle from "@/components/StepTitle";
import BackgroundCard from "@/components/BackgroundCard";
import ButtonWrapper from "@/components/ButtonWrapper";
import InstallerContext from "@/context/InstallerContext";
import CompatibilityLoading from "@/components/CompatibilityLoading";
import PrevButton from "@/components/PrevButton";

const Compatibility = ({nextStep}) => {
  const {t} = useTranslation();
  const {data, setCompatibility, setWelcome} = useContext(InstallerContext);
  const {compatibility} = data;
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  
  useEffect(() => {
    getCompatibility().then(({data}) => {
      setCompatibility(data);
      for (const element of data) {
        if (element.status === 'error') {
          setDisabled(true);
          break;
        }
      }
      setLoading(false);
    }).catch(error => {
      console.log(error);
    });
  }, [])
  
  return (
      <Step>
        <StepTitle>
          {t('steps.1.title')}
        </StepTitle>
        
        <If condition={loading}>
          <Then>
            <div className="mb-8 md:mb-0">
              <CompatibilityLoading/>
            </div>
          </Then>
          <Else>
            <BackgroundCard>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 md:mb-0">
                {compatibility.map(element =>
                    <CompatibilityCard
                        key={element.label}
                        status={element.status}
                        label={element.label}
                        content={element.content}
                    />
                )}
              </div>
            </BackgroundCard>
          </Else>
        </If>
        
        <ButtonWrapper>
          <PrevButton previousStep={() => setWelcome(true)}/>
          <NextButton nextStep={nextStep} disabled={loading || disabled}/>
        </ButtonWrapper>
      </Step>
  );
};

export default Compatibility;