import React, {useContext} from 'react';
import StepWizard from "react-step-wizard";

import Sidebar from "@/components/Sidebar";

import Welcome from "@/components/steps/Welcome";
import Compatibility from "@/components/steps/Compatibility";
import DatabaseSettings from "@/components/steps/DatabaseSettings";
import GeneralSettings from "@/components/steps/GeneralSettings";
import CreateAdmin from "@/components/steps/CreateAdmin";
import Finish from "@/components/steps/Finish";
import InstallerContext from "@/context/InstallerContext";

const SetupWizard = () => {
  const {data} = useContext(InstallerContext);
  
  if (data.welcome) return <Welcome />
  
  return (
      <StepWizard
          isLazyMount={true}
          nav={<Sidebar />}
          className="relative bg-white flex relative overflow-hidden w-full"
      >
        <Compatibility />
        <DatabaseSettings />
        <GeneralSettings />
        <CreateAdmin />
        <Finish />
      </StepWizard>
  );
};

export default SetupWizard;