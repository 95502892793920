import React from 'react';
import {useTranslation} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

import Step from "@/components/Step";
import StepTitle from "@/components/StepTitle";


const Completed = () => {
  const {t} = useTranslation();
  
  return (
      <Step>
        <StepTitle>
          {t('steps.5.finish.title')}
        </StepTitle>
        
        <div className="bg-emerald-50 rounded-xl mt-6 py-6 px-10 text-emerald-500">
          <div className="mx-auto w-20 h-20 rounded-2xl bg-emerald-200 flex items-center justify-center">
            <FontAwesomeIcon icon={faCheck} className="text-3xl" />
          </div>
          <div className="max-w-lg mt-4 text-center mx-auto text-lg">
            {t('steps.5.finish.description')}
          </div>
        </div>
        
        <a href="/dashboard" className="cursor-pointer rounded-xl py-3 px-6 flex gap-3 bg-vox/90 transition hover:bg-vox items-center text-white mx-auto w-fit mt-6">
          {t('steps.5.finish.button')}
        </a>
        
        <div className="hidden md:block mt-auto text-gray-100 text-5xl font-bold select-none">
          LEADEROS
        </div>
      </Step>
  );
};

export default Completed;