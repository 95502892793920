import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {setGeneralSettings} from "@/lib/axios";

import InstallerContext from "@/context/InstallerContext";

import Step from "@/components/Step";
import StepTitle from "@/components/StepTitle";
import PrevButton from "@/components/PrevButton";
import NextButton from "@/components/NextButton";
import BackgroundCard from "@/components/BackgroundCard";
import ButtonWrapper from "@/components/ButtonWrapper";
import ErrorMessage from "@/components/ErrorMessage";
import Label from "@/components/Label";
import InputGroup from "@/components/InputGroup";
import FormGroup from "@/components/FormGroup";
import Input from "@/components/Input";
import InputGrid from "@/components/InputGrid";
import CurrencySelector from "@/components/CurrencySelector";
import TimezoneSelector from "@/components/TimezoneSelector";

const GeneralSettings = ({nextStep, previousStep}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const {data, updateGeneralData} = useContext(InstallerContext)
  const {general} = data;
  
  const handleNextStep = (e) => {
    e.preventDefault();
    setLoading(true);
    setGeneralSettings(general)
      .then(({data}) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setErrors([]);
          nextStep();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const disabled = loading || !general.serverName || !general.serverIp || !general.serverVersion || !general.websiteTitle || !general.currency || !general.timezone || !general.maintenance;
  
  return (
      <Step>
        <StepTitle>
          {t('steps.3.title')}
        </StepTitle>
        
        <ErrorMessage errors={errors} />
        
        <BackgroundCard>
          <FormGroup>
            <Label htmlFor="serverName">
              {t('steps.3.inputs.server_name')}
            </Label>
            <InputGroup error={general.serverName === ''}>
              <Input
                  type="text"
                  id="serverName"
                  placeholder="example: LeaderOS"
                  value={general.serverName || ""}
                  onChange={e => updateGeneralData('serverName', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="title">
              {t('steps.3.inputs.website_title')}
            </Label>
            <InputGroup error={general.websiteTitle === ''}>
              <Input
                  type="text"
                  id="title"
                  placeholder="example: The Best Minecraft Server"
                  value={general.websiteTitle || ""}
                  onChange={e => updateGeneralData('websiteTitle', e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <InputGrid>
            <FormGroup>
              <Label htmlFor="ip">
                {t('steps.3.inputs.server_ip')}
              </Label>
              <InputGroup error={general.serverIp === ''}>
                <Input
                    type="text"
                    id="ip"
                    placeholder="example: play.hypixel.net"
                    value={general.serverIp || ""}
                    onChange={e => updateGeneralData('serverIp', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="version">
                {t('steps.3.inputs.server_version')}
              </Label>
              <InputGroup error={general.serverVersion === ''}>
                <Input
                    type="text"
                    id="version"
                    placeholder="example: 1.18"
                    value={general.serverVersion || ""}
                    onChange={e => updateGeneralData('serverVersion', e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </InputGrid>
          
          <InputGrid>
            <FormGroup>
              <Label htmlFor="currency">
                {t('steps.3.inputs.currency')}
              </Label>
              <CurrencySelector />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="title">
                {t('steps.3.inputs.timezone')}
              </Label>
              <TimezoneSelector />
            </FormGroup>
          </InputGrid>
          <InputGrid>
            <FormGroup>
              <Label htmlFor="passwordEncryption">
                {t('steps.3.inputs.password_encryption')}
              </Label>
              <select
                  className="input"
                  id="passwordEncryption"
                  value={general.passwordEncryption || "1"}
                  onChange={e => updateGeneralData('passwordEncryption', e.target.value)}
              >
                <option value="1">SHA256</option>
                <option value="2">MD5</option>
                <option value="3">bcrypt</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="maintenance">
                {t('steps.3.inputs.maintenance')}
              </Label>
              <select
                  className="input"
                  id="maintenance"
                  value={general.maintenance || "0"}
                  onChange={e => updateGeneralData('maintenance', e.target.value)}
              >
                <option value="0">{t('disable')}</option>
                <option value="1">{t('enable')}</option>
              </select>
            </FormGroup>
          </InputGrid>
        </BackgroundCard>
        
        <ButtonWrapper>
          <PrevButton previousStep={previousStep} />
          <NextButton nextStep={handleNextStep} disabled={disabled} loading={loading} />
        </ButtonWrapper>
      </Step>
  );
};

export default GeneralSettings;