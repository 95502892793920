import axios from "axios";

export const client = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    baseURL:
        process.env.NODE_ENV === 'development' ?
            'http://leaderos-test.com:8888/apps/install/public/ajax' : '/apps/install/public/ajax',
});

export const getLanguages = async () => await client.get('/install.php?data=languages');
export const getCurrencies = async () => await client.get('/install.php?data=currencies');
export const getTimezones = async () => await client.get('/install.php?data=timezones');
export const getCompatibility = async () => await client.get('/install.php?step=compatibility');
export const setDatabase = (data) => client.post('/install.php?step=database', {
    mysqlServer: data.host,
    mysqlPort: data.port,
    mysqlDatabase: data.database,
    mysqlUsername: data.username,
    mysqlPassword: data.password,
});
export const setGeneralSettings = (data) => client.post('/install.php?step=general', {
    siteSlogan: data.websiteTitle,
    siteServerName: data.serverName,
    siteServerIP: data.serverIp,
    siteServerVersion: data.serverVersion,
    sitePasswordType: data.passwordEncryption,
    siteMaintenance: data.maintenance,
    siteLanguage: data.language,
    siteCurrency: data.currency,
    siteTimezone: data.timezone,
});
export const createAdmin = (database, admin) => client.post('/install.php?step=admin', {
    mysqlServer: database.host,
    mysqlPort: database.port,
    mysqlDatabase: database.database,
    mysqlUsername: database.username,
    mysqlPassword: database.password,
    accountUsername: admin.username,
    accountEmail: admin.email,
    accountPassword: admin.password,
    accountPasswordRe: admin.confirmPassword,
});
export const run = (database, general, admin) => client.post('/install.php?step=run', {
    mysqlServer: database.host,
    mysqlPort: database.port,
    mysqlDatabase: database.database,
    mysqlUsername: database.username,
    mysqlPassword: database.password,
    
    siteSlogan: general.websiteTitle,
    siteServerName: general.serverName,
    siteServerIP: general.serverIp,
    siteServerVersion: general.serverVersion,
    sitePasswordType: general.passwordEncryption,
    siteMaintenance: general.maintenance,
    siteLanguage: general.language,
    siteCurrency: general.currency,
    siteTimezone: general.timezone,
    
    accountUsername: admin.username,
    accountEmail: admin.email,
    accountPassword: admin.password,
    accountPasswordRe: admin.confirmPassword,
});

export const check = () => client.get('/check.php');