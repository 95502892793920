import React from 'react';

import {When} from "react-if";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion, faCheck, faCircle} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({totalSteps, currentStep}) => {
  const {t} = useTranslation();
  
  const steps = [];
  for (let i = 1; i <= totalSteps; i++) {
    const isActive = currentStep === i;
    const isDone = currentStep > i;
    const finish = currentStep === totalSteps && i === totalSteps;
    steps.push((
        <div key={`step-${i}`} className={`relative flex gap-3 items-center ${!isDone && !isActive && 'opacity-75 transition'}`}>
          <div className={`${finish ? 'bg-emerald-500 text-white' : (isDone || isActive ? 'bg-vox text-white' : 'bg-gray-200 text-gray-400')} rounded-2xl w-12 h-12 flex items-center justify-center transition duration-300`}>
            <FontAwesomeIcon icon={isDone || finish ? faCheck : faCircle} />
            <When condition={i < totalSteps}>
              <span className={`${isDone || isActive ? 'bg-vox' : 'bg-gray-200'} absolute -bottom-8 left-[22px] h-8`} style={{width: "3px"}}></span>
            </When>
          </div>
          <span className={`${finish ? '!text-emerald-500' : (isDone || isActive ? 'text-vox' : 'text-gray-500')} font-semibold`}>
            {t(`steps.${i}.title`)}
          </span>
        </div>
    ));
  }
  return (
      <div className="hidden relative z-10 w-[30rem] bg-gray-100 rounded-l-2xl py-14 px-12 md:flex flex-col gap-8">
        {steps}
        
        <a href="https://help.leaderos.net" target="_blank" rel="noreferrer" className="self-end mt-auto flex gap-3 items-center opacity-75 transition hover:opacity-100">
          <span className="text-gray-500 text-sm font-medium">
            {t('help')}
          </span>
          <div className="w-8 h-8 flex items-center justify-center rounded-xl bg-gray-200">
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        </a>
      </div>
  );
};

export default Sidebar;