import React, {useContext, useEffect, useState} from 'react';
import {check, run} from "@/lib/axios";

import InstallerContext from "@/context/InstallerContext";

import Installing from "@/components/Installing";
import Completed from "@/components/Completed";
import InstallingError from "@/components/InstallingError";

const Finish = ({isActive}) => {
  const {data} = useContext(InstallerContext);
  const {database, general, admin} = data;
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  
  const checker = () => {
    check()
        .then(({data}) => {
          if (data.status) {
            setCompleted(true);
          } else {
            checker();
          }
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
  }
  
  useEffect(() => {
    if (isActive) {
      run(database, general, admin)
          .then(({data}) => {
            if (data.success) {
              checker();
            } else {
              setError(true);
              setErrorMessages(data.errors);
              console.log(data);
            }
          })
          .catch((error) => {
            setError(true);
            console.log(error);
          });
    }
  }, [isActive, data]);
  
  if (error)
    return <InstallingError messages={errorMessages} />;
  
  if (!completed)
    return <Installing />;
  
  return <Completed />;
};

export default Finish;