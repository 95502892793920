import React, {useContext, useEffect, useState} from 'react';
import {When} from "react-if";
import ClickAwayListener from 'react-click-away-listener';
import {getTimezones} from "@/lib/axios";
import InstallerContext from "@/context/InstallerContext";
import {useTranslation} from "react-i18next";
import {useDebounce} from "use-debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const TimezoneSelector = () => {
  const {t} = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [search] = useDebounce(searchText, 500);
  const {data, updateGeneralData} = useContext(InstallerContext);
  const {general} = data;
  
  const handleChange = (timezone) => {
    setDropdownOpen(false);
    setSearchText('');
    updateGeneralData("timezone", timezone);
  }
  
  useEffect(() => {
    getTimezones().then(({data}) => {
      setTimezones(data);
    });
  }, []);
  
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  }
  
  return (
      <ClickAwayListener onClickAway={() => {
        setDropdownOpen(false);
        setSearchText('');
      }}>
        <div className="relative w-full">
          <button onClick={() => setDropdownOpen(!dropdownOpen)} className="input text-left w-full">
            {timezones.find(k => k.value === general.timezone)?.label || t('select_timezone')}
          </button>
          
          <When condition={dropdownOpen}>
            <div className="absolute w-full rounded-xl bg-gray-100 pb-2 h-52 overflow-auto bottom-14 border border-gray-200">
              <div className="relative">
                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-500" />
                </div>
                <input
                    type="search"
                    onInput={handleSearch}
                    value={searchText}
                    placeholder={t('search')}
                    className="w-full px-4 pl-10 py-3 text-sm bg-gray-100 border-0 border-b !border-gray-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
                />
              </div>
              {timezones
                  .filter(
                    (timezone) => timezone.label.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((data) =>
                      <div key={data.value} onClick={() => handleChange(data.value)} className="py-2 px-4 text-gray-500 text-sm transition hover:bg-gray-200/75 cursor-pointer">
                        {data.label}
                      </div>
                  )}
            </div>
          </When>
        </div>
      </ClickAwayListener>
  );
};

export default TimezoneSelector;